body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #222;
    color: #fafafa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
