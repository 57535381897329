.viewer {
  display: flex;
  justify-content: center;
  align-content: center;
  background: #111;
  max-height: 100%;
  height: 100%;
  overflow: hidden;

  &.blur {
    filter: blur(8px);
  }
}

.imgViewer {
  max-height: 100%;
  display: flex;
  justify-content: center;
  user-select: none;

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .imgViewer {
    max-height: calc(100vh - 53px);
  }

  .viewer {
    overflow: hidden;
  }
}

.pdfViewer {
  margin: auto;
  max-width: 100% !important;
  height: auto !important;
  background: #111;
}

.opacity0 {
  opacity: 0.0;
}

.outerGameContainer {
  max-height: calc(100vh - 53px);
  height: 100%;
}

.outerDrawerContainer {
  max-height: calc(100vh - 53px);
  min-width: 170px;
  overflow-y: auto;
}

.itemFolder {
  text-transform: uppercase;
}

.itemFile {
}


.imgUnzoomed {
  cursor: zoom-in;
}
.imgZoomed {
  cursor: zoom-out;
}
